<template>
  <v-container class="fill-height secondary un-bg" fluid>
    <v-row no-gutters align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card flat class="elevation-3">
          <v-toolbar color="primary" dark flat>
            <v-img max-height="40" max-width="40" src="/logo.png" />
            <v-toolbar-title
              class="text-uppercase font-weight-bold subtitle-2"
              style="padding: 0 3px;"
              >UNDSS SRS</v-toolbar-title
            >
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-form ref="form" @submit.prevent v-model="valid">
              <v-text-field
                v-model="model.username"
                :label="$t('login.usernameOrEmail')"
                name="login"
                prepend-icon="mdi-account-circle"
                type="text"
              />

              <v-text-field
                v-model="model.password"
                id="password"
                :label="$t('user.password')"
                name="password"
                prepend-icon="mdi-lock"
                :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                :type="showPassword ? '' : 'password'"
                @keyup="hitEnter"
                @click:append="toggleShowPassword"
              />

              <div class="d-flex justify-end">
                <v-btn
                  small
                  color="primary"
                  text
                  @click="() => $router.push({ name: 'forgot-password' })"
                  >Forgot Password?</v-btn
                >
              </div>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              color="primary"
              tile
              depressed
              @click="navigateToRegister"
            >
              {{ $t("login.register") }}
            </v-btn>
            <v-spacer />
            <v-btn
              color="primary"
              tile
              depressed
              :loading="loading"
              @click="tryLogin"
            >
              {{ $t("login.login") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters align="center" justify="center">
      <copyright :size="64" />
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import Copyright from "@/common/components/Copyright";

export default {
  name: "Login",
  components: { Copyright },
  data() {
    return {
      valid: false,
      showPassword: false,
      loading: false,
      redirect: "/",
      model: { username: "", password: "" },
    };
  },
  methods: {
    ...mapActions("auth", ["login"]),

    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },

    hitEnter(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.tryLogin();
      }
    },
    tryLogin() {
      this.$refs.form.validate();

      setTimeout(() => {
        if (this.valid) {
          this.loading = true;
          this.login(this.model)
            .then(() => {
              this.$router.push({ name: "home" });
              this.loading = false;
            })
            .catch(() => (this.loading = false));
        }
      }, 300);
    },
    navigateToRegister() {
      this.$router.push({ name: "register" });
    },
  },
};
</script>
